/* *************************************************************
 * Main app module for misc JS code.
 */

//  MOBILE NAV MENU
jQuery(document).ready(function($){
    // Get query parameters for customSearch
    $.urlParam = function(name){
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (results==null) {
           return null;
        } else {
           return results[1] || 0;
        }
    }
    $('.expand-menu').click(function(e){
        e.preventDefault();
        $(this).toggleClass('open');
        if($('nav.small.top ul').is(':hidden')){
            $('nav.small.top ul').slideDown();
        }else{
            $('nav.small.top ul').slideUp();
        }

    });

    // Set parameters for the Custom Search API
    const searchApi = {
        // The query string to search
        q : $.urlParam('q') ? decodeURI($.urlParam('q')) : null,
        // The page number to display
        page : $.urlParam('page') && $.urlParam('page') > 0 ? $.urlParam('page') : 1,
        // The custom search engine ID to scope this search query (string)
        cx : '001445688698826140732:ray_mbwbfuy',
        // Number of search results to return (int)
        num : 10,
        // API key
        key : 'AIzaSyBrZ9VrtEjPdsdaIxedLRvvntD7Q_9ennA',
    }
    // Which result to start with
    searchApi.start = ((searchApi.page - 1 ) * searchApi.num) + 1;

    // URL for the search API endpoint
    const searchEndpoint = 'https://www.googleapis.com/customsearch/v1/siterestrict?' + $.param( searchApi, true);
    if(searchApi.q !== null) {
        // Caching selectors
        const $searchResults = $('#searchResults');
        const $searchNav = $('#searchNav');
        $.getJSON(searchEndpoint, function (data) {
            // Get the total number of results and pages
            const totalResults = data.queries.request[0].totalResults &&
                data.queries.request[0].totalResults >= 0 ?
                data.queries.request[0].totalResults : 0;
            /*
            // The Google API only seems to return the first 100 results
            // We are commenting this out for now because it was going to be used to display page numbers
            const totalPages = Math.ceil(totalResults / searchApi.num);
            */

            // Display a message if there are no results.
            if(totalResults === 0 || !data.queries) {
                $searchResults.append('<p>Your search returned no results.</p>');
                return;
            }

            // Append the results
            $(data.items).each(function() {
                // Create a container for each result
                $searchResults.append('<div id="' + this.cacheId + '" data-url="' + this.formattedUrl + '" class="search-results"></div>');
                // Append the content for each result
                $('#' + this.cacheId)
                    .append('<h3><a href="' + this.link + '">' + this.title + '</h3></a>')
                    .append('<p>' + this.snippet + '</p>');
            });

            // For each result, go to the url when any area of the result is clicked.
            $('.search-results').click(function() {
                window.location.href = $(this).attr('data-url');
            });

            //If there are results, display the pagination
            if(totalResults > 0) {
                $searchNav.append('<ul></ul>');
                const $searchNavUl = $searchNav.find('ul');
                // Append a previous button
                if(data.queries.previousPage) {
                    $searchNavUl.append('<li><a href="?q=' + searchApi.q + '&amp;page=' + (parseInt(searchApi.page) - 1) + '">Previous</a></li>');
                }

                /*
                // We're commenting this out because the API doesn't return the correct data for >100 results
                for(let i = searchApi.page; i < totalPages; i++) {
                    $('#searchNav ul').append('<li><a href="?q=' + searchApi.q + '&amp;page=' + i + '">' + i + '</a></li>')
                }
                */

                //Append a next button
                if(data.queries.nextPage) {
                    $searchNavUl.append('<li><a href="?q=' + searchApi.q + '&amp;page=' + (parseInt(searchApi.page) + 1) + '">Next</a></li>');
                }
            }
        })
    }
});
